html {
  overflow-y: scroll;
}

body {
  background-color: rgb($purple);

  &.s-show-content,
  &.s-completed {
    will-change: background-color, color;
    transition: background-color 400ms 100ms ease-in-out,
      color 400ms 100ms ease-in-out;
  }

  &.s-menu-open {
    @mixin bpMax $tablet {
      overflow: hidden;
    }
  }

  &.s-show-content {
    background-color: rgb($bgColor);
  }

  &.s-grey {
    background-color: rgb($grey);
    color: rgb($color);
  }

  &.s-beige,
  &.single-profile {
    background-color: rgb($beige);
    color: rgb($color);

    .c-links a {
      color: rgb($purple);
    }
  }

  &.single-media_item,
  &.s-black {
    background-color: rgb($black);
    color: rgb($white);
  }

  &.s-green {
    background-color: rgb($green);
    color: rgb($black);
  }

  &.s-purple {
    background-color: rgb($purple);
    color: rgb($white);
  }
  &.page-template-template-room-room-front {
    background-color: rgb($grey);
  }
}

.c-header,
.c-page {
  opacity: 0;

  .s-show-content & {
    opacity: 1;
  }
}

.c-main,
.l-main {
  min-height: 100vh;

  @mixin bp $tablet {
    padding-top: 3.5rem;
  }
}

.s-hidden {
  display: none;
}

.search-results .pagination-block {
  max-width: 900px;
  position: relative;
  margin: 0 auto;
}

.search-results ul.pagination li {
  width: 74px;
  display: inline-block;
}

.search-results ul.pagination {
  list-style: none;
}
