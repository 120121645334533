@import '../global/variables.css';
@import '../global/mixins.css';

.c-exhibition {
  text-align: center;
  display: block;
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.75rem;
  will-change: color, background-color, border-color;
  transition: color 200ms ease-out, background-color 200ms ease-out,
    border-color 200ms ease-out;

  background-color: rgb($greyDark);

  @mixin bpMax $tablet {
    font-size: 0.875rem;
  }

  &__link {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    margin-bottom: $spaceMediumTablet;
  }

  &__text {
    text-align: left;

    &__title {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.2;
      font-weight: $fwRegular !important;
    }
    &__artist {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.2;
      font-weight: $fwRegular;
      font-style: italic;
    }
  }

  &__dates {
    text-align: left;
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: $fwRegular;

    p {
      margin: 0;
    }
  }

  &__thumbnail {
    margin-bottom: 0;
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 66.66666%;
    overflow: hidden;
    background-color: rgb($purple);

    .c-media {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &__inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        &:hover {
          mix-blend-mode: difference;
        }
      }
    }
  }

  &__logo svg {
    width: 100%;
    height: auto;
  }

  &:hover {
    border-color: rgb($purple);
    background-color: rgb($purple);
    box-shadow: 0 0 0.75rem rgb($purple);
    color: rgb($white) !important;

    & svg {
      transition: fill 200ms ease-out;
      fill: rgb($white);
    }

    & .c-media img {
      transition: mix-blend-mode 200ms ease-out;
      mix-blend-mode: difference;
    }
  }
}
